import React from "react";

import useSideBar from "../../../../hooks/useSideBar";
import Hamburger from "./hamburger";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import { Overlay, Wrapper } from "./styles";

export const Header = () => {
  const [sidebar, toggle] = useSideBar();

  return (
    <Wrapper>
      <Overlay sidebar={sidebar} onClick={toggle} />
      <Navbar />
      <Hamburger sidebar={sidebar} toggle={toggle} />
      <Sidebar sidebar={sidebar} toggle={toggle} />
    </Wrapper>
  );
};

export default Header;
