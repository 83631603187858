import "./sidebar-submenu.scss";

import * as React from "react";
import { PropsWithChildren, useLayoutEffect, useState } from "react";

const SidebarSubmenu: React.FC = (props: PropsWithChildren<any>) => {
  const [active, setActive] = useState(false);
  useLayoutEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 1);
  }, []);
  //<editor-fold desc="arrowRight">
  const arrowRight = (
    <svg
      width={"14"}
      height={"14"}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-circle-right"
      className={"dot-circle"}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"></path>
    </svg>
  );
  //</editor-fold>
  return (
    <div className={`o-sidebar-submenu o-sidebar-submenu--active`}>
      <div className="m-submenu">{props.children}</div>
    </div>
  );
};

export default SidebarSubmenu;
