import "./hero.scss";

import * as React from "react";
import { PropsWithChildren, useEffect, useLayoutEffect } from "react";

export interface HeroProps extends PropsWithChildren<any> {
  title: string;
  subtitle?: string;
  photo: any;
  photo_alt: string;
  color?: string;
  price?: number;
  minutes?: number;
  isMinutes?: boolean;
}

const Hero: React.FC<HeroProps> = (props: HeroProps) => {
  const {
    title,
    minutes,
    /*price,*/ subtitle,
    photo,
    color,
    photo_alt,
    children,
    isMinutes,
  } = props;

  useLayoutEffect(() => {
    document.body.style.setProperty("--hover", "#A3BCC4");
  }, []);

  useEffect(() => {
    if (color) {
      document.body.style.setProperty("--hover", color);
    }
  }, [color]);

  return (
    <>
      <section>
        <div className="hero-background">
          <svg width="0" height="0">
            <defs>
              <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                <path d="M 0,0 H 1 V 0.6 C 1,0.6 0.50714872,0.98351558 0.35,0.98496487 0.22689092,0.98610023 0.04614615,0.63365878 0,0.6 Z" />
              </clipPath>
              <clipPath id="myClipDevice" clipPathUnits="objectBoundingBox">
                <path d="M 0,0 H 1 V 0.75 C 1,0.75 0.50714872,0.98303314 0.35,0.98448244 0.22689092,0.98561779 0.04614615,0.88365878 0,0.85 Z" />
              </clipPath>
            </defs>
          </svg>
          <img
            src={photo}
            className={"cut"}
            alt={photo_alt}
            width={600}
            height={300}
          />
        </div>
        <div className={"o-hero"}>
          <div className={"m-hero-content"}>
            {isMinutes && (
              <div
                className={
                  "m-card m-card--minutes  m-card--border m-card--no-shadow"
                }
                style={{
                  backgroundColor: `${color}`,
                  color: "#fff",
                }}
              >
                <p
                  className={
                    "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
                  }
                >
                  {minutes}
                </p>
                <p className={"a-card__paragraph"}>minutos</p>
              </div>
            )}
            <p className={"h1 a-hero-title"}>{title}</p>
            {subtitle && (
              <h1
                className={"a-hero-subtitle"}
                style={{
                  color: `${color}`,
                }}
              >
                {subtitle}
              </h1>
            )}
            {children}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
