import Layout from "../../../../src/views/shared/layout/layout";
import { SEO } from "../../../../src/components/SEO/SEO";
import Hero from "../../../../src/views/shared/hero/Hero";
import img from "../../../../src/assets/images/hero-home.png";
import * as React from 'react';
export default {
  Layout,
  SEO,
  Hero,
  img,
  React
};