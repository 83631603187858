import "./contact.scss";

import React from "react";

import ContactFormHelper from "./ContactForm/ContactFormHelper";

export const Contact = () => {
  return (
    <div className="m-wrapper" id="contact">
      <ContactFormHelper />
    </div>
  );
};

export default Contact;
