import styled from "styled-components";

export const Wrapper = styled.div`
	 // tip to view always scroll bar and avoid break on click
	 overflow-x: hidden;
	a {
		text-decoration: none;
		@media (max-width: 1400px) {
			color: #000;
			&:hover {
				color: #7b7b7b;
			}
		}
	}
	@media (max-width: 1400px) {
	   min-height: 101vh;
	}

	${({ desktop }) =>
		desktop
			? `
			align-items: center;
			display: flex;

			@media (max-width: 1400px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
			: `
			padding: 2rem 2.55rem;
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: auto;
			
			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
					
					@media (max-width: 1400px) {
						text-align: center;
						padding: 0.7em 0;
					}
			}
	`}
`;
