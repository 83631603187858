import { PropsWithChildren } from "react";
import * as React from "react";

import { Bar, Wrapper } from "./styles";

interface Props extends PropsWithChildren<any> {
  sidebar: boolean;
  toggle: any;
}

const Hamburger: React.FC<Props> = (props: Props) => {
  const { toggle, sidebar } = props;
  return (
    <Wrapper sidebar={sidebar} onClick={toggle}>
      <Bar top sidebar={sidebar} />
      <Bar mid sidebar={sidebar} />
      <Bar bottom sidebar={sidebar} />
    </Wrapper>
  );
};

export default Hamburger;
