import useForm from "hooks/useForm";
import React from "react";

import { ContactForm } from "./";

const ContactFormHelper = (params) => {
  const { handleSubmit, submitting, success, fail } = useForm(params);

  return <ContactForm {...{ handleSubmit, submitting, success, fail }} />;
};

export default ContactFormHelper;
