// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-legal-mdx": () => import("./../../../src/pages/legal/aviso-legal.mdx" /* webpackChunkName: "component---src-pages-legal-aviso-legal-mdx" */),
  "component---src-pages-legal-politica-de-cookies-mdx": () => import("./../../../src/pages/legal/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-mdx" */),
  "component---src-pages-legal-politica-de-privacidad-mdx": () => import("./../../../src/pages/legal/politica-de-privacidad.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-mdx" */),
  "component---src-pages-tarifas-js": () => import("./../../../src/pages/tarifas.js" /* webpackChunkName: "component---src-pages-tarifas-js" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog/category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-technique-technique-tsx": () => import("./../../../src/templates/technique/technique.tsx" /* webpackChunkName: "component---src-templates-technique-technique-tsx" */),
  "component---src-templates-training-training-tsx": () => import("./../../../src/templates/training/training.tsx" /* webpackChunkName: "component---src-templates-training-training-tsx" */)
}

