import "styles/index.scss";

import * as React from "react";
import { useState } from "react";

import BannerCookies from "../../../components/GTM/Banner";
import Whatsapp from "../../../components/Whatsapp/Whatsapp";
import ModalForm from "../modalForm/ModalForm";
import { Footer } from "./footer";
import Header from "./header";

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const Layout: React.FC<Props> = (props: Props): JSX.Element => {
  const [has_modal, setHasModal] = useState(false);

  return (
    <>
      <BannerCookies />
      {has_modal && <ModalForm title="Contáctanos" is_active={setHasModal} />}
      <span
        className={"a-button a-button__layout"}
        onClick={() => {
          setHasModal(true);
        }}
      >
        Contáctanos
      </span>
      <Header />
      {props.children}
      <Footer setHasModal={setHasModal} />
      <Whatsapp />
    </>
  );
};

export default Layout;
