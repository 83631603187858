import { PropsWithChildren } from "react";
import * as React from "react";

import NavbarLinks from "../navbarLinks";
import { Wrapper } from "./styles";

interface Props extends PropsWithChildren<any> {
  sidebar: boolean;
  toggle: any;
}

const Sidebar: React.FC<Props> = (props: Props) => {
  return (
    <Wrapper active={props.sidebar} onClick={props.toggle}>
      <NavbarLinks />
    </Wrapper>
  );
};

export default Sidebar;
