import "./contact-form.scss";

import React from "react";

export const ContactForm = ({ handleSubmit, submitting, success, fail }) => (
  <form
    className="m-form"
    name="contact-home"
    method="post"
    netlify-honeypot="bot-field"
    data-netlify="true"
    onSubmit={handleSubmit}
  >
    <div
      className={
        "grid-x2 grid-x2--break-pro grid-x2--large-gap grid-x2--large-gap-responsive"
      }
    >
      <div>
        <input type="hidden" name="form-name" value="contact-home" />
        <label htmlFor={"name"} className={"a-input-title"}>
          Nombre
        </label>
        <input
          type="text"
          name="name"
          placeholder="Escribe aquí tu nombre"
          required
        />
        <p className="hidden">
          <input type="text" name="bot-field" placeholder="Nombre" />
        </p>
      </div>
      <div>
        <label htmlFor={"last-name"} className={"a-input-title"}>
          Apellido
        </label>
        <input
          type="text"
          name="last-name"
          placeholder="Escribe aquí tu apellido"
          required
        />
        <p className="hidden">
          <input type="text" name="bot-field" placeholder="Apellido" />
        </p>
      </div>
    </div>
    <div
      className={
        "grid-x2 grid-x2--break-pro grid-x2--large-gap grid-x2--large-gap-responsive"
      }
    >
      <div>
        <label htmlFor={"email"} className={"a-input-title"}>
          Email
        </label>
        <input
          type="email"
          name="email"
          placeholder="Escribe aquí tu e-mail"
          required
        />
      </div>
      <div>
        <label htmlFor={"telephone"} className={"a-input-title"}>
          Teléfono
        </label>
        <input
          type="tel"
          name="telephone"
          placeholder="Escribe aquí tu teléfono"
          pattern="[0-9]{9-14}"
          required
        />
        <p className="hidden">
          <input type="text" name="bot-field" placeholder="Teléfono" />
        </p>
      </div>
    </div>
    <textarea
      name="message"
      placeholder="Escribe tu mensaje aquí..."
      required
    ></textarea>
    <div className={"grid-70-30 grid-70-30--break-pro p-top"}>
      <div className="o-private">
        <label htmlFor="politica">
          {" "}
          He leído y acepto la{" "}
          <a
            href="/legal/politica-de-privacidad/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            política de privacidad
          </a>{" "}
        </label>
        <input
          type="checkbox"
          name="politica"
          id="politica"
          value="acepta-politica"
          required
        />
      </div>

      <div className="center">
        {!success && (
          <button className="a-button" type="submit">
            Enviar {submitting && <span className="a-spinner" />}
          </button>
        )}
      </div>
    </div>
    {fail && (
      <p className="a-fail">
        Ha ocurrido un <b>error</b>, puedes ponerte en contacto a través de:{" "}
        <a href="mailto:info@thecookies.agency"> info@thecookies.agency</a>
      </p>
    )}
    {success && <p className="a-success">Formulario enviado con éxito</p>}
  </form>
);
