module.exports = {
  defaultTitle: "Obelion Fisioterapeutas | Centro fisioterapia en Zaragoza",
  author: "TheCookies Agency",
  siteUrl: "https://obelionfisioterapia.com/",
  url: "https://obelionfisioterapia.com/",
  legalName: "Obelion Physiotherapy & Wellness S.L.",
  name: "Obelion Fisioterapia Zaragoza",
  defaultDescription: "Reserva tu cita en nuestro centro de Fisioterapia en Zaragoza. ✓ Fisioterapia de última generación ✓ Pilates motor control ✓ Obelion Training ¡Te esperamos!",
  logo: "https://obelionfisioterapia.com/logowh-obelion.png",
  logotypeUrl: "https://obelionfisioterapia.com/logowh-obelion.png",
  socialLinks: {
    facebook: "https://www.facebook.com/ObelionPW/",
    twitter: "https://twitter.com/Obelion_PW",
    linkedin: "",
    instagram: "https://www.instagram.com/obelion_pw/",
    telegram: "",
    vimeo: "",
    youtube: "",
    tiktok: "",
    medium: "",
    spotify: "",
    podcast: "",
  },
  social: {
    facebook: "@",
    twitter: "@",
    linkedin: "@",
    instagram: "@",
    telegram: "@",
  },
  address: {
    streetAddress: "C/ Lacarra de Miguel 42",
    city: "Zaragoza",
    region: "Zaragoza",
    country: "España",
    zipCode: "50008",
  },
  contact: {
    email: "info@obelionfisioterapia.com",
    phone: "+34640902082",
  },
  foundingDate: "2022",
};
