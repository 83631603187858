import "./footer.scss";

import { Link } from "gatsby";
import * as React from "react";

export const Footer = ({ setHasModal }) => {
  return (
    <div className={"o-footer"}>
      <div className={"m-footer-content container"}>
        <h2 className={"a-footer__title"}>
          Somos tus fisioterapeutas en el centro de Zaragoza
        </h2>
        <div className={"grid-x2 grid-x2--break-pro"}>
          <div className={"m-info"}>
            <p>
              Somos especialistas en diagnóstico por imagen, gracias a la
              ecografía musculoesquelética localizamos el origen de tu problema.
              Ponemos a tu servicio los tratamientos y técnicas más
              vanguardistas para obtener los mejores resultados.
            </p>
            <p>
              Además nuestra filosofía consiste en que entiendas que detrás de
              cada lesión hay un aprendizaje. Te enseñamos cómo funciona tu
              cuerpo, explicándote por qué te has lesionado, y qué acciones
              debes realizar para que no vuelva a producirse.
            </p>
            <p>
              Nuestras instalaciones están diseñadas para que te sientas lo más
              cómodo posible, y que tu estancia en nuestro centro sea la mejor
              de las experiencias.
            </p>
            <div className={"m-contact-data"}>
              <p className={"m-contact-data__item"}>
                <span className={"a-highlight"}>Teléfono</span>{" "}
                <a href={"tel:+34640902082"}>640 90 20 82</a>
              </p>
              <p className={"m-contact-data__item"}>
                <span className={"a-highlight"}>Mail</span>{" "}
                <a href={"mailto:info@obelionfisioterapia.com"}>
                  info@obelionfisioterapia.com
                </a>
              </p>
              <p className={"m-contact-data__item"}>
                <span className={"a-highlight"}>Dirección</span> C/ Lacarra de
                Miguel nº42 Local Izda. 50008
              </p>
            </div>
            <span
              className={"a-button"}
              onClick={() => {
                setHasModal(true);
              }}
            >
              Contáctanos
            </span>
          </div>
          <div className={"a-map"}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.550381635321!2d-0.883306884676923!3d41.64384907924144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5914e417bb1913%3A0x245d2e05ef768f0e!2sOBELION%20P%26W%20Fisioterapeutas%20-%20Centro%20fisioterapia%20Zaragoza!5e0!3m2!1ses!2ses!4v1632226046552!5m2!1ses!2ses"
              width="100%"
              height="400"
              style={{
                border: 0,
              }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <div className={"m-footer-bottom"}>
        <div className="grid-x2 grid-x2--break-pro container">
          <div className="m-legal">
            <a
              href="/legal/aviso-legal/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Aviso legal
            </a>{" "}
            -{" "}
            <a
              href="/legal/politica-de-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Política de privacidad
            </a>{" "}
            -{" "}
            <a
              href="/legal/politica-de-cookies/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Cookies
            </a>{" "}
            -{" "}
            <b>
              <Link to="/blog/">Blog</Link>
            </b>
          </div>
          <div className="a-details">© 2021 Obelion</div>
        </div>
      </div>
    </div>
  );
};
