import "./whatsapp.scss";

// import logoWhatsapp from "./whatsapp-brands.svg";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const Whatsapp: React.FC = () => {
  const phone: string = "+34640902082";
  const message: string = "Hola! Quería disfrutar de la promoción para nuevos pacientes del 15% de descuento en la primera cita.";
  return (
    <a
      href={`https://wa.me/${phone}?text=${encodeURI(message)}`}
      target={"_blank"}
      rel={"noopener noreferrer nofollow"}
    >
      <div className={"m-whatsapp"}>
        <StaticImage
          src={"./whatsapp-brands.svg"}
          alt={"icono de whatsapp"}
          aria-label={"contactar por whatsapp"}
          width={48}
        />
        {/*<img src={logoWhatsapp} width={"48"} height={"48"} />*/}
      </div>
    </a>
  );
};

export default Whatsapp;
