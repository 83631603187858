import "./navbar.scss";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "assets/images/logo-white.png";
import { Link } from "gatsby";
import { useState } from "react";
import * as React from "react";

import ModalBook from "../../../modalForm/ModalBook";
import NavbarLinks from "../navbarLinks";

const Navbar = () => {
  return (
    <div className="m-header container wrapper grid-x3">
      <Link to="/">
        <img src={logo} alt="Obelion Fisioterapia" width="200" height="35" />
      </Link>
      <NavbarLinks desktop />
      <a
        href={"https://reservaweb.viday.es/#/client/66e197675bfb42f33ec1bd2d"}
        target={"_blank"}
        className={"a-button"}
        rel="noreferrer"
      >
        Pide cita
      </a>
      {/*<a*/}
      {/*  href={*/}
      {/*    "https://www.treebole-clinicas.net/es/customer/requestappo/cusrc/g84fzxc8vvaguap"*/}
      {/*  }*/}
      {/*  target={"_blank"}*/}
      {/*  rel={"noopener noreferrer nofollow"}*/}
      {/*  className={"a-button"}*/}
      {/*>*/}
      {/*  Pide cita*/}
      {/*</a>*/}
    </div>
  );
};

export default Navbar;
